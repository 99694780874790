import React from "react";
import Seo from "../../../components/seo";
import ogIMage from "../../../images/og.png";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../../components/Layout";
import Vimeo from "@u-wave/react-vimeo";
import IntroductionVideo from "../../../images/opentextevent/opentext.mp4";
import videothumbnail from "../../../images/opentextevent/videothumbnail.png";

const EventDetailPage = ({ location, title, ctaLink, ctaText }) => {
    return (
        <Layout>
            <Seo
                title={" Events - Opentextworld"}
                description={
                    "Get to know details about all the exciting events, technology conferences, business gatherings and more our team will be heading to. Explore our schedule."
                }
                url={location.href}
                image={`https://sedintechnologies.com${ogIMage}`}
            />
            {/* Header section  */}

            {/* event-details-landing page-hero section */}
            <section className="events-details-hero-static">
                <div className="container container-first-v3">
                    <div className="event-details__text-img">
                        <StaticImage
                            src="../../../images/opentextevent/Opentext.svg"
                            height="150px"
                            width="675px"
                            className="event-svg-img"
                            // style={{ alignItems: "center" }}
                        />
                    </div>
                    <div className="event-details__text">
                        <h1 className="text-h1 flex flex-jc-c flex-ai-c opentextTitle">
                            Expert Sponsor
                        </h1>
                        <div className="event-details__text-opentextpartner flex flex-jc-c flex-ai-c">
                            <div className="partner-summit-one">
                                <h3 className="text-h3">OpenText Partner Summit</h3>
                                <h3 className="text-h3-color">October 10 | Las Vegas</h3>
                            </div>
                            <div className="partner-summit-two">
                                <h3 className="text-h3">OpenText World 2023</h3>
                                <h3 className="text-h3-color">October 11-12 | Las Vegas</h3>
                            </div>
                        </div>
                        <div className="meet-at flex flex-jc-c flex-ai-c">
                            <Link className="sdn-cta-btn" to="/contact/">
                                <h2 className="text-h2"> Meet us at Booth #316</h2>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            {/* we are proud sponser section */}
            <section className="event-details-proudsponser">
                <div className="container container--mid-v4">
                    <div className="event-details__text">
                        <h1 className="text-h1-heading">
                            We are a Proud Sponsor of OpenText World 2023
                        </h1>
                        <p className="text-p1">
                            OpenText World is the ultimate information management conference. From
                            October 11-12, leading experts, thought-leaders, industry peers will
                            converge at The Venetian Resort in Las Vegas to shape the future of
                            information management.
                        </p>
                    </div>
                </div>
            </section>
            <section className="video-section">
                <div className="container container--pb">
                    {/* <Vimeo height="684" video={"https://vimeo.com/871045724"} responsive={true} /> */}
                    <video
                        controls
                        height="70%"
                        width="100%"
                        controlsList="nodownload"
                        poster={videothumbnail}
                    >
                        <source src={IntroductionVideo} type="video/mp4" />
                    </video>
                </div>
            </section>
            {/* group chat points section */}
            <section className="event-details-group-chat-point">
                <div className="container container--mid-v4">
                    <div className="event-details__text">
                        <h1 className="text-h1-group-heading">
                            We would love to CHAT with you, you can expect
                        </h1>
                        <ul className="first">
                            <li>
                                Meaningful discussion about your challenges or roadmap for
                                information management
                            </li>
                            <li>
                                A walkthrough of how we implemented end-to-end information
                                management solutions across industries
                            </li>
                            <li>Demos on how to work smarter and faster with OpenText</li>
                            <li>
                                Exciting opportunity to network with experts to help take on the
                                challenges ahead
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            {/* meet out team section */}
            <section className="event-details-meet-out-team">
                <div className="container container--mid-v4">
                    <div className="event-details__text">
                        <h1 className="text-h1 out-team-heading">
                            Meet our Team at OpenText World Las Vegas
                        </h1>
                    </div>
                    <div className="leaders__grid">
                        <div className="leaders__grid-item">
                            <StaticImage
                                src="../../../images/opentextevent/Kabir.png"
                                placeholder="blurred"
                                className="kabir-img"
                                loading="lazy"
                            />
                            <p className="leader-name text-p1 text-clr-primary text-fw-medium">
                                Kabir Mitra
                            </p>
                            <p className="text-p4 text-clr-secondary text-fw-medium">
                                VP-Global Sales
                            </p>
                        </div>
                        <div className="leaders__grid-item">
                            <StaticImage
                                src="../../../images/opentextevent/Hari.png"
                                placeholder="blurred"
                                className="hari-img"
                                loading="lazy"
                            />
                            <p className="leader-name text-p1 text-clr-primary text-fw-medium">
                                Hari Prasad
                            </p>
                            <p className="text-p4 text-clr-secondary text-fw-medium">
                                ECM Practice Head
                            </p>
                        </div>
                        <div className="leaders__grid-item">
                            <StaticImage
                                src="../../../images/opentextevent/Louis.png"
                                placeholder="blurred"
                                className="louis-img"
                                loading="lazy"
                            />
                            <p className="leader-name text-p1 text-clr-primary text-fw-medium">
                                Louis Innocent
                            </p>
                            <p className="text-p4 text-clr-secondary text-fw-medium">
                                Business Development Manager
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            {/* venue section */}
            <section className="event-details-venue">
                <div className="container container--mid-v4">
                    <div className="event-details__text ">
                        <div className="event-details__text-flex">
                            <div className="venue-address">
                                <h1 className="text-h1-venue">Venue</h1>
                                <div className="venue-address-main">
                                    <p className="venue-address-street">
                                        The Venetian Resort Hotel Casino
                                        <br />
                                        3355 Las Vegas Blvd. South, Las Vegas, NV 89109
                                    </p>
                                </div>
                                <div className="venue-address-bottom">
                                    <p className="venue-address-date">October 11-12, 2023</p>
                                </div>
                            </div>
                            <div className="venue-img">
                                <StaticImage
                                    src="../../../images/opentextevent/venueimg.png"
                                    placeholder="blurred"
                                    loading="lazy"
                                    alt="venue-img"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* service section  */}
            <section className="service-contact">
                <div className="container container--last">
                    {/* <BlogCtaBanner
                        title={data.pageData.callToActionBanner.header}
                        ctaLink={data.pageData.callToActionBanner.ctaSlug}
                        ctaText={data.pageData.callToActionBanner.ctaText}
                    /> */}
                    <div className="cta-banner">
                        <div className="cta-banner__grid-item">
                            <div className="cta-banner__grid-item__text">
                                <div className="text-h2 cta-text text-fw-medium">
                                    See How our Information Management Solution is Powering Up
                                    Businesses Globally
                                </div>
                                <Link className=" sdn-cta-btn" to="/contact/">
                                    Book a Dedicated Slot
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default EventDetailPage;
